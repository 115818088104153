/* Estilos gerais */
body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/*----------HEADER------------*/

.headerPos {
    position: relative;
    height: 100vh; /* Altura total da viewport */
    overflow: hidden;
}

.background-img {
    width: 100%;
    height: 100%; /* Garante que a imagem cubra 100% da altura e largura */
    object-fit: cover; /* Cobre a área inteira mantendo a proporção */
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; /* Coloca a imagem atrás dos textos */
}

.header-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 1;
    display: flex; /* Coloca os itens lado a lado */
    flex-direction: row; /* Garante que os itens fiquem em linha */
    gap: 20px; /* Espaçamento entre os itens */
    align-items: center; /* Alinha os itens verticalmente */
    text-align: left; /* Texto alinhado à esquerda */
    justify-content: center; /* Centraliza horizontalmente */
}

/* Estilos do h2 e span */
.header-text h2 {
    font-size: 2rem;
    margin: 0 20px 0 0; /* Espaçamento à direita */
    font-weight: normal;
}




.about-pos {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Centraliza verticalmente */
}

.pos-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pos-row {
    display: flex;
    justify-content: space-between; /* Garante que a imagem e o texto fiquem lado a lado */
    align-items: center;
    width: 80%; /* Para centralizar melhor o conteúdo */
}

pos-row h3{
    color: #80FF80;
}

.pos-text p {
    font-size: 14px; /* Texto menor */
    line-height: 1.5;
    margin-left: 100px; /* Espaço entre texto e imagem */
    text-align: justify; /* Texto justificado para melhor formatação */
    max-width: 700px; /* Largura fixa para quebrar as linhas */
    word-wrap: break-word; /* Quebrar texto longo */
}

.pos-row-bottom {
    flex-direction: column; /* Coloca o h2 abaixo do p */
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}


.pos-title-row {
    display: flex;
    align-items: center; /* Alinha verticalmente o h2, a linha e o botão */
    justify-content: center;
    width: 100%; /* Garante que a linha ocupe o restante do espaço */
}

.pos-title-row h2 {
    margin-right: 10px; /* Espaço entre o h2 e a linha */
    white-space: nowrap; /* Evita quebra de linha no h2 */
}

.pos-title-row hr {
    flex-grow: 1; /* Faz a linha expandir para ocupar o espaço ao lado do h2 */
    margin: 0 10px; /* Espaçamento entre a linha e o botão */
    border: 0;
    border-top: 2px solid #ccc; /* Estilo da linha */
}

.about-btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #80FF80;
    border: none;
}



.pos-text span {
    font-weight: bold; /* Negrito no span */
}


.fluxosPos {
    background-color: #e6e6e6;
    text-align: center;
}

.fluxos-list {
    display: flex;
    justify-content: center; /* Centraliza os itens */
    align-items: center; /* Alinha verticalmente */
    gap: 30px; /* Aumenta o espaço entre os itens para um espaçamento confortável */
    padding: 0;
    margin: 0;
    text-align: left;
}

.fluxo-item {
    position: relative;
}


.fluxo-item-pos img {
    width: 100%; /* Tamanho intermediário para as imagens */
    height: auto;
    margin-right: 15px; /* Espaçamento entre as imagens */
}



.section-divider {
    text-align: center;
    position: relative;
    /* Posicionamento relativo para o contexto do botão */
    margin: 40px 0;
    /* Espaçamento acima e abaixo da divisão */
}

.img-btn {
    position: absolute;
    top: 80%;
    left: 60%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    background-color: #80FF80;
    /* Cor de fundo do botão */
    color: rgb(0, 0, 0);
    /* Cor do texto do botão */
    border: none;
    /* Remove a borda */
    cursor: pointer;
    font-size: 0.7rem;
    z-index: 2;
    /* Garante que o botão fique acima da imagem */
    transition: background-color 0.3s ease;
    /* Transição suave para o hover */
}

.img-btn:hover {
    background-color: #52a052;
    /* Cor de fundo no hover */
}
@media (max-width: 918px) {
    .header-text {
        display: flex;
        flex-direction: column;
    }

    .pos-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 40px 0;
        align-items: center;
        width: 100%;
    }

    .pos-row p {
        margin: 0;
    }

    .pos-Image img {
        width: 400px;
    }

    .fluxo-item img {
        max-width: 100px;
        height: auto;
        margin-right: 15px;
    }
}