/* Estilos gerais */
body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/*----------HEADER------------*/

.header {
    position: relative;
    height: 100vh; /* Altura total da viewport */
    overflow: hidden;
    display: flex;
    justify-content: center; /* Centraliza o conteúdo horizontalmente */
    align-items: center; /* Centraliza o conteúdo verticalmente */
}

.background-img {
    width: 100%;
    height: 100%; /* Garante que a imagem cubra 100% da altura e largura */
    object-fit: cover; /* Cobre a área inteira mantendo a proporção */
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; /* Coloca a imagem atrás dos textos */
}

.highlight {
    position: relative;
    display: inline;
    font-weight: 600; 
  }
  
  .highlight::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 75%;
    height: 0.4em;
    background-color: #80FF80;
    z-index: -1;
    opacity: 0.5;
    transform: translateY(-50%);
  }

  .highlight2 {
    position: relative;
    display: inline-block;;
    font-weight: 600; 
  }
  
  .highlight2::before {
    content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 55%;
  height: 0.4em;
  background-color: #80FF80;
  opacity: 0.5;
  z-index: 0;
  }

.header-text-1 {
    display: flex; /* Alinha os elementos em linha */
    flex-direction: row; /* Divide em duas colunas */
    gap: 40px; /* Espaçamento entre as colunas */
    width: 80%; /* Define largura máxima */
    max-width: 1250px; /* Limita a largura total */
    color: white;
    z-index: 1; /* Garante que o texto fique na frente */
}

.header-column {
    flex: 1; /* Cada coluna ocupa 50% do espaço */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
}

.header-column h2 {
    line-height: 1.4; /* Melhora o espaçamento entre as linhas */
    margin: 0;
    font-weight:normal
}

.header-column h2 span {
    font-weight:bold
}

.header-column p {
    line-height: 1.6; /* Espaçamento entre as linhas */
    margin: 0;
    max-width: 600px; /* Limita a largura do texto */
}

/*----------ABOUT------------*/
.aboutEmbed {
    background-color: #ffffff;
    text-align: center;
}

.aboutEmbed-content {
    display: flex; /* Coloca as divs lado a lado */
    justify-content: space-between; /* Espaçamento entre as divs */
    align-items: flex-start; /* Alinha os itens no início verticalmente */
    max-width: 1200px; /* Largura máxima para limitar o layout */

}

.aboutEmbed-content > div {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-align: start;
    position: relative;
}

.icon-image {
    width: 50px; /* Deixa os ícones menores */
    height: auto;
    align-self: flex-start; /* Alinha os ícones ao início do texto */
}

.person-image {
    width: 100%; /* Ocupa toda a largura disponível */
    height: auto;
    max-width: 300px; /* Limita a largura da imagem */
    margin-top: 20px; /* Espaçamento entre o texto e a imagem */
}

.person-image1 {
    width: 100%; /* Ocupa toda a largura disponível */
    height: auto;
    max-width: 300px; /* Limita a largura da imagem */
    margin-top: 20px; /* Espaçamento entre o texto e a imagem */
}

.aboutEmbed-content p {
    margin: 0;
    padding: 0 20px;
    font-size: 1rem;
    line-height: 1.5;
}

.aboutEmbed-content span {
    font-weight: bold;
}

.aboutEmbed-content button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #80ff80;
    color: white;
    border: none;
    cursor: pointer;
}

.aboutEmbed-content button:hover {
    background-color: #57a457;
}

.aboutEmbed button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #80ff80;
    color: white;
    border: none;
    cursor: pointer;
}

.aboutEmbed button:hover {
    background-color: #57a457;
}


/*----------ABOUt-TECNOLOGIES------------*/
.about-tecnologies {
    background-color: #80FF80; /* Ajuste o fundo conforme necessário */
    padding: 20px 20px; /* Ajuste o espaçamento interno */
    text-align: center;
}

.about-tecnologies h2 {
    color: white;
}

.about-tecnologies-info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Colunas flexíveis que ocupam uma única linha */
    grid-template-rows: auto; /* Apenas uma linha */
    justify-items: center; /* Centraliza os itens em cada célula */
    align-items: center;
}

.tec-image {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tec-image img {
    width: 70px; /* Ajuste o tamanho da imagem conforme necessário */
    height: auto;
    margin-bottom: 10px;
}

.tec-image h5 {
    
    color: #000000;
}




/*----------LANGUAGES------------*/


.language-list-wrapper {
    position: relative;
    width: 100%;
    /* Ajusta para 100% da largura do contêiner pai */
    max-width: 800px;
    /* Limita a largura máxima para 600px */
    margin: 0 auto;
    /* Centraliza o carrossel */
    overflow: hidden;
    /* Esconde as imagens que estão fora da área visível */
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Espaça as setas à direita e esquerda */
    padding: 0 20px;
    /* Adiciona padding para espaçamento das setas */
}

.language-item {
    display: flex;
    transition: transform 0.5s ease-in-out;
    /* Animação suave para o carrossel */
    gap: 20px;
    /* Espaçamento entre as imagens */
    width: fit-content;
    padding: 0 40px;
    /* Permite que o conteúdo se ajuste ao tamanho necessário */
}

.language-img {
    width: 100px;
    /* Tamanho das imagens */
    height: 100px;
    /* Define a altura para manter a proporção */
    object-fit: contain;
    /* Garante que as imagens não fiquem distorcidas */
}

.icon-image {
    width: 40px;
    /* Tamanho das setas */
    height: 40px;
    /* Tamanho das setas */
    cursor: pointer;
    /* Muda o cursor para indicar que é clicável */
}

.left-arrow {
    position: absolute;
    left: -20px;
    background-color: transparent;
    /* Posiciona à esquerda */
    top: 50%;
    height: 60px;
    z-index: 100;
    transform: translateY(-50%);
    /* Centraliza verticalmente */
}

.right-arrow {
    position: absolute;
    background-color: transparent;
    z-index: 100;
    right: 0;
    height: 60px;
    /* Posiciona à direita */
    top: 50%;
    transform: translateY(-50%);
    /* Centraliza verticalmente */
}

@media (max-width: 800px) {
    .language-list-wrapper {
        max-width: 90%;
        /* Ajusta para 100% da largura do contêiner pai em telas menores */
        padding: 0 10px;
        /* Reduz o padding para telas menores */
    }

    .language-img {
        width: 80px;
        /* Reduz o tamanho das imagens em telas menores */
        height: 80px;
        /* Reduz o tamanho das imagens em telas menores */
    }

    .icon-image {
        width: 30px;
        /* Reduz o tamanho das setas em telas menores */
        height: 30px;
        /* Reduz o tamanho das setas em telas menores */
    }
    .left-arrow {
    position: absolute;
    left: -10px;
    background-color: white;
    /* Posiciona à esquerda */
    top: 50%;
    left: -21px;
    height: 60px;
    z-index: 100;
    transform: translateY(-50%);
    /* Centraliza verticalmente */
}

.right-arrow {
    position: absolute;
    background-color: white;
    z-index: 100;
    right: -21px;
    height: 60px;
    /* Posiciona à direita */
    top: 50%;
    transform: translateY(-50%);
    /* Centraliza verticalmente */
}
}







.so-item {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.so-img {
    width: 130px; /* Tamanho ajustado das imagens */
    height: auto;
    transition: transform 0.3s ease;
}

.so-img:hover {
    transform: scale(1.2); /* Efeito de zoom ao passar o mouse */
}



/* Seção Depoimentos */
.makeEmbed {
    background-color: #F0F0F0;
    text-align: center;
}


.makeEmbed h4 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: black;
}


.makeEmbed button{
    background-color: #80ff80;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
   

}

.makeEmbed button:hover {
    background-color: #57a457;
}



/*----------MEMBROS------------*/


.members {
    background-color: #000000;
    padding: 20px 20px;
    text-align: center;
}

.members h4 {
    font-size: 1.6rem;
    margin-bottom: 5px;
    color: #80FF80;
}

.members-list {
    display: flex;
    justify-content: center; /* Centraliza os itens horizontalmente */
    gap: 100px; /* Espaçamento entre os membros */
}

.memberEmbed {
    padding: 20px;
    text-align: center;
    width: 300px;
}

.memberEmbed img {
    width: 100%;
    margin-bottom: 10px;
}

.memberName {
    font-size: 1rem;
    margin: 10px 0;
    color: #ffffff;
}

.memberFunction {
    font-size: 1rem;
    color: #ffffff;
    margin-bottom: 10px;
}


/*----------DIVIDER------------*/
.member-section-divider {
    text-align: center;
    position: relative;
    /* Posicionamento relativo para o contexto do botão */
}

.member-img-btn {
    position: absolute;
    /* Posiciona o botão de forma absoluta dentro do contêiner */
    top: 80%;
    /* Move o botão para 50% da altura da imagem */
    left: 60%;
    /* Move o botão para 50% da largura da imagem */
    transform: translate(-50%, -50%);
    /* Centraliza o botão */
    padding: 10px 20px;
    background-color: #66cc66;
    /* Cor de fundo do botão */
    color: rgb(0, 0, 0);
    /* Cor do texto do botão */
    border: none;
    /* Remove a borda */
    cursor: pointer;
    font-size: 0.7rem;
    z-index: 2;
    /* Garante que o botão fique acima da imagem */
    transition: background-color 0.3s ease;
    /* Transição suave para o hover */
}

.img-btn:hover {
    background-color: #52a052;
    /* Cor de fundo no hover */
}
@media(max-width:918px) {
    .header-text-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 1;
    display: flex; /* Coloca os itens lado a lado */
    flex-direction: column; /* Garante que os itens fiquem em linha */
    gap: 20px; /* Espaçamento entre os itens */
    align-items: center; /* Alinha os itens verticalmente */
    text-align: left; /* Texto alinhado à esquerda */
    }
    
    .member-section-divider img{
        width: 380px;
    }
    .member-section-divider .member-img-btn{
        width: 180px;
        left: 70%;
    }
    .header-text-1 h2 {
        text-align: center;
        margin: 0;
    }
    /* Estilos do p */
    .header-text-1 p {
        text-align: center;
        margin: 0;
    }


    .aboutEmbed-content {
        justify-content: center;
        flex-direction: column;
        padding: 0 2rem;
    }
    
}

@media (max-width: 800px) {
    .about-tecnologies-info {
        grid-template-columns: 1fr;
        row-gap: 25px;
        /* Reduz para 1 coluna em telas ainda menores */
    }
      
}
