/* Estilos gerais */
body,
html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    box-sizing: border-box;
}

/*----------HERO------------*/
.hero {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
  }
  
  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  .hero-text {
    z-index: 1;
    max-width: 90%; 
  }
  
  .hero-text h1, .hero-text h2 {
    margin: 0.5rem 0;
    font-weight: 200;
    font-size: clamp(2rem, 3vw, 3rem); 
    line-height: 1.4;
  }
  
  .highlight {
    position: relative;
    display: inline-block;
    font-weight: 600; 
  }
  
  .highlight::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 75%;
    height: 0.4em;
    background-color: #80FF80;
    z-index: -1;
    opacity: 0.5;
    transform: translateY(-50%);
  }

/*----------ABOUT------------*/
.about {
    background-color: #80FF80;
    /* Cor de fundo clara */
    padding: 35px 20px;
    text-align: center;
}

.about-content {
    max-width: 950px;
    margin: 0 auto;
    color: #333;
    align-items: center;
}

.about-content-text h2 {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    color: white;
    font-weight: 300;
    /* Remover position: absolute */
    margin-bottom: 1rem; /* Adiciona espaçamento abaixo do título */
}

.about-content-text img {
    width: 4rem;
    display: inline-block;
    margin-left: 10px; /* Espaço entre o texto e a imagem */
    vertical-align: middle; /* Alinha a imagem verticalmente com o texto */
}

.about-content button {
    margin-top: 5rem;
    padding: 10px;
    color: #80FF80;
    border: none;
    cursor: pointer;
}

.about-image {
    padding-top: 0.5rem;
}

.about h1 {
    color: aliceblue;
    font-size: 2rem;
    margin-bottom: 20px;
    white-space: nowrap;
}

.about p {
    font-size: 1.2rem;
    line-height: 1.6;
}

.about-content button {
    margin-top: 5rem;
    padding: 10px 20px; 
    color: #80ff80; /* Cor do texto */
    border: none;
    cursor: pointer;
    font-size: 20px; /* Aumenta o tamanho da fonte */
    font-family: 'Roboto', sans-serif; /* Define uma nova fonte */
}


/*----------ABOUT-SOLUTIONS------------*/
.about-solutions {
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.solutions-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1250px;
    width: 100%;
    gap: 30px;
    /* Espaçamento entre texto e imagem */
}

.solutions-text {
    flex: 1;
}

.solutions-image img {
    width: 600px;
    /* Defina o tamanho apropriado da imagem */
    height: auto;
}

.solutions-heading {
    color: #80FF80;
    /* Altera a cor para verde conforme o modelo da imagem */
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 22px;
}

h1 {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.2;
}

.solutions-list {
    padding: 0;
    margin: 0;
    flex-basis: 50%;
    /* Ocupa metade da largura */
    text-align: left;
    /* Alinha o texto à esquerda */
}

.solutions-list li {
    margin-bottom: 10px;
}

.solutions-list li::before {
    content: ''; /* Adiciona o marcador como um elemento vazio */
    position: absolute;
    left: 0; /* Posiciona à esquerda do texto */
    top: 50%; /* Centraliza verticalmente */
    transform: translateY(-50%); /* Ajuste fino para centralizar */
    width: 10px; /* Tamanho da bola */
    height: 10px; /* Tamanho da bola */
    background-color: #80FF80; /* Cor verde */
    border-radius: 50%; /* Deixa a bola redonda */
}


.solutions-image {
    flex-basis: 50%;
    /* Ocupa metade da largura */
    display: flex;
    justify-content: flex-end;
    /* Alinha a imagem à direita */
}

.solutions-image img {
    max-width: 100%;
    height: auto;
}

/*----------ABOUT-DLL------------*/
.about-dll {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #80FF80;
    height: auto;
}

.about-dll .text1 h3,
.about-dll .text2 h3,
.about-dll .text3 h3 {
    text-align: left;
}

.about-dll p {
    margin: 4px 0; 
    line-height: 1.2;
}

.about-dll .text1 ,
.about-dll .text2 ,
.about-dll .text3  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.about-dll h3 {
    margin: 5px 0;
    font-size: 1.5rem;
}

.about-dll span {
    color: white;
    font-weight: bold;
}

.about-dll h3 span {
    font-weight: bold;
}


/*----------SOLUTIONS------------*/
.solutions {
    background-color: white;
    padding: 40px 50px;
}

.solutions h2 {
    font-size: 2rem;
    margin-bottom: 50px;
    color: #333;
}

.solution-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 1100px;
    /* Limita a largura total para caber 3 cards por linha */
    margin: 0 auto;
}

.solution-item {
    background-color: white;
    width: 300px;
    height: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    border-radius: 0.375rem;
    

}


.solution-item:hover {
    transform: scale(1.05);
}

.solution-img {
    width: 100%;
    height: 200px;
    /* Aumenta a altura da imagem para ocupar o topo */
    object-fit: cover;
    margin-bottom: 0;
}

.solution-desc {
    font-size: 1.3rem;
    color: #80FF80;
    padding: 20px;
    text-align: center;
}

.solution-btn,
.coming-soon {
    background-color: #80FF80;
    border: none;
    font-size: 1rem;
    color: #333;
    width: 80%;
    /* Faz com que o botão não ocupe toda a largura do card */
    transition: background-color 0.3s ease;
    margin-top: 20px;
    /* Adiciona um espaço entre o texto e o botão */
    margin-bottom: 20px;
    /* Suspende o botão, deixando espaço abaixo */
    padding: 10px;
    border-radius: 0.375rem;
}

.solution-btn:hover,
.coming-soon:hover {
    background-color: #66cc66;
}

.coming-soon {
    background-color: #f0f0f0;
    color: #666;
}

.coming-soon:hover {
    background-color: #ddd;
    color: #444;
}



/*----------DIVIDER------------*/
.section-divider {
    width: 100%;
}
.section-divider img{
    width: 100%;
}

.img-btn {
    position: absolute;
    top: 80%;
    left: 70%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    background-color: #80FF80;
    /* Cor de fundo do botão */
    color: rgb(0, 0, 0);
    /* Cor do texto do botão */
    border: none;
    /* Remove a borda */
    cursor: pointer;
    font-size: 0.7rem;
    z-index: 2;
    /* Garante que o botão fique acima da imagem */
    transition: background-color 0.3s ease;
    /* Transição suave para o hover */
}

.img-btn:hover {
    background-color: #52a052;
    /* Cor de fundo no hover */
}

/*----------PAREIROS------------*/


.partners {
    background-color: #1c1c1c;
    /* Cor de fundo escura para destacar as empresas parceiras */
    padding: 40px 20px;
    text-align: center;
}

.partners p {
    color: white;
}

.partners h4 {
    color: #80FF80;
    font-size: 1.7rem;
    margin-bottom: 50px;
}

.partners-list {
    display: grid;
    /* Usamos grid para controlar a disposição das imagens */
    grid-template-columns: repeat(5, 1fr);
    /* 4 colunas */
    grid-template-rows: repeat(2, auto);
    /* 2 linhas */
    gap: 20px;
    /* Espaço entre as imagens */
    justify-items: center;
    /* Centraliza as imagens dentro das células */
    align-items: center;
    margin-bottom: 50px;
}

.partners-list img {
    object-fit: contain;
    filter: grayscale(100%);
    /* Efeito de imagem em tons de cinza */
    transition: filter 0.3s ease;
}

.partners-list img:hover {
    filter: grayscale(0);
    /* Remove o efeito de tons de cinza no hover */
}


/* Seção Depoimentos */
.testimonials {
    background-color: #000000;
    padding: 50px 0;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.title {
    color: #80FF80;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
}

.testimonials h4 {
    font-size: 1.8rem;
    margin-bottom: 40px;
    color: white;
    font-weight: 600;
}

.testimonials-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
}

.testimonial-card {
    flex: 0 0 45%;
    max-width: 400px;
    height: 280px;
    display: flex; /* Adiciona flexbox */
    flex-direction: column; /* Organiza o conteúdo em coluna */
    justify-content: center; /* Centraliza verticalmente */
    align-items: center; /* Centraliza horizontalmente */
    text-align: center; /* Garante que o texto fique centralizado */
    padding: 20px;
    background-color: #80FF80;
    color: black;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    position: relative;
    transition: transform 0.3s ease-in-out;
}

.testimonial-card blockquote {
    font-style: italic;
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.5;
    color: #000000;
    text-align: center; /* Centraliza o texto dentro do bloco */
}
.testimonial-card:hover {
    transform: scale(1.05);
}



.carousel-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    padding: 0 20px;
}

.prev-btn,
.next-btn {
    background-color: transparent;
    color: #80FF80;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.prev-btn:hover,
.next-btn:hover {
    transform: scale(1.2);
}

/* Home.css */

/* Estilos para a nova seção de opções */
@media (max-width: 767px) {
    .testimonials-carousel {
        flex-direction: column;
        gap: 20px;
    }

    .testimonial-card {
        flex: 1 0 100%;
    }

    .carousel-controls {
        display: none; /* Esconde os botões em telas menores */
    }
}