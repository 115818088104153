.headerCommunity {
    position: relative;
    height: 100vh; /* Altura total da viewport */
    overflow: hidden;
    background-color: #80FF80;
}

.headerCommunity img{
    width: 100%;
}



/*----------ABOUT------------*/

.aboutEmbed {
    background-color: #ffffff;
}

.aboutEmbed-content {
    display: flex;
    justify-content: space-between; /* Garante que o texto fique à esquerda e a imagem à direita */
    align-items: center; /* Centraliza verticalmente o conteúdo */
    max-width: 1200px; /* Limita a largura da seção */
    margin: 0 auto; /* Centraliza a seção */
}

.aboutEmbed-content > div {
    flex: 1; /* Distribui o espaço igualmente */
}

.aboutEmbed-content p {
    margin: 10px 0;
    padding: 0;
    font-size: 1rem;
}

.aboutEmbed-content span {
    font-weight: bold;
}

.personCommunity {
    width: 400px; /* Define o tamanho da imagem */
    height: auto;
    object-fit: contain; /* Garante que a imagem não seja distorcida */
    margin-left: 40px; /* Adiciona espaço entre a imagem e o texto */
}





/*----------ABOUt-COMMUNITY------------*/

.about-community {
    background-color: #000000;
    padding: 40px 20px;
    text-align: center;
}

.about-community h2 {
    color: white;
}

.about-community p {
    color: #80FF80;
}

.about-community-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-items: center;
    margin-top: 30px;
}

.benefic-image {
    display: flex;
    align-items: center; /* Alinha verticalmente a imagem e o texto */
    justify-content: flex-start; /* Alinha horizontalmente a imagem e o texto à esquerda */
    text-align: left; /* Alinha o texto à esquerda */
    max-width: 300px; /* Defina um limite de largura */
}

.benefic-image img {
    width: 100px;
    height: auto;
    margin-right: 20px; /* Espaço entre a imagem e o texto */
}

.benefic-text {
    display: flex;
    flex-direction: column;
}

.benefic-text h5 {
    font-size: 1rem;
    color: #80FF80; /* Define a cor verde */
    margin: 0;
}

.benefic-text h6 {
    font-size: 0.9rem;
    color: #cccccc;
    margin: 0;
}




.community-divider {
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: center;
}

.community-img-divider {
    width: 100%;
    max-width: 1200px;
    height: auto;
}

.community-img-btn {
    position: absolute;
    top: 79%;
    left: 37%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    background-color: #66cc66;
    color: rgb(0, 0, 0);
    border: none;
    cursor: pointer;
    font-size: 1rem;
    z-index: 2;
    transition: background-color 0.3s ease;
}
.community-img-btn:hover {
    background-color: #52a052;
}


@media (max-width: 1200px) {
    .about-community-info {
        grid-template-columns: repeat(2, 1fr);
        /* Reduz para 2 colunas em telas menores */
    }
}

@media (max-width: 800px) {
    .about-community-info {
        grid-template-columns: 1fr;
        /* Reduz para 1 coluna em telas ainda menores */
    }
    .personCommunity {
    width: 310px;
    margin-left: 0;
}

}





