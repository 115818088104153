/* Estilos gerais */
body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/*----------HEADER------------*/

.headerTef {
    position: relative;
    height: 100vh; /* Altura total da viewport */
    overflow: hidden;
}

.background-img {
    width: 100%;
    height: 100%; /* Garante que a imagem cubra 100% da altura e largura */
    object-fit: cover; /* Cobre a área inteira mantendo a proporção */
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; /* Coloca a imagem atrás dos textos */
}

.header-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 1;
    display: flex; /* Coloca os itens lado a lado */
    flex-direction: row; /* Garante que os itens fiquem em linha */
    gap: 20px; /* Espaçamento entre os itens */
    align-items: center; /* Alinha os itens verticalmente */
    text-align: left; /* Texto alinhado à esquerda */
    justify-content: center; /* Centraliza horizontalmente */
}

/* Estilos do h2 e span */
.header-text h2 {
    font-size: 2rem;
    margin: 0 20px 0 0; /* Espaçamento à direita */
    font-weight: normal;
}




.about-tef {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tef-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.tef-row {
    display: flex;
    flex-direction: row-reverse; /* Imagem à esquerda e texto à direita */
    justify-content: center;
    align-items: center;
    width: 80%; /* Para centralizar melhor o conteúdo */
}

.tef-text p {
    font-size: 16px; /* Texto menor */
    line-height: 1.8;
    text-align: start; /* Texto justificado para melhor formatação */
    max-width: 400px; /* Largura fixa para quebrar as linhas */
    word-wrap: break-word; /* Quebrar texto longo */
}

.tef-row-bottom {
    flex-direction: column; /* Coloca o h2 abaixo do p */
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}


.tef-title-row {
    display: flex;
    align-items: center; /* Alinha verticalmente o h2, a linha e o botão */
    justify-content: center;
    width: 100%; /* Garante que a linha ocupe o restante do espaço */
}

.tef-title-row h2 {
    white-space: nowrap; /* Evita quebra de linha no h2 */
}

.tef-title-row hr {
    flex-grow: 1; /* Faz a linha expandir para ocupar o espaço ao lado do h2 */
    margin: 0 10px; /* Espaçamento entre a linha e o botão */
    border: 0;
    border-top: 2px solid #ccc; /* Estilo da linha */
}

.about-btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #80FF80;
    border: none;
}



.tef-text span {
    font-weight: bold; /* Negrito no span */
}


.fluxos {
    background-color: #e6e6e6;
    text-align: center;
}

.fluxos-list {
    display: flex;
    justify-content: center; /* Centraliza os itens */
    align-items: center; /* Alinha verticalmente */
    padding: 0;
    margin: 0;
    text-align: left;
}

.fluxo-item {
    position: relative;
}


.fluxo-item img {
    max-width: 200px; /* Tamanho intermediário para as imagens */
    height: auto;
}



.section-divider {
    text-align: center;
    position: relative;
    /* Posicionamento relativo para o contexto do botão */
    margin: 40px 0;
    /* Espaçamento acima e abaixo da divisão */
}

.img-btn {
    position: absolute;
    top: 80%;
    left: 60%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    background-color: #80FF80;
    /* Cor de fundo do botão */
    color: rgb(0, 0, 0);
    /* Cor do texto do botão */
    border: none;
    /* Remove a borda */
    cursor: pointer;
    font-size: 0.7rem;
    z-index: 2;
    /* Garante que o botão fique acima da imagem */
    transition: background-color 0.3s ease;
    /* Transição suave para o hover */
}

.img-btn:hover {
    background-color: #52a052;
    /* Cor de fundo no hover */
}

@media (max-width: 918px) {
    .header-text {
        display: flex;
        flex-direction: column;
    }
    .tef-row{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .tef-row p{
        margin: 0;
    }
    .tef-Image img{
        width: 800px;
    }
    .fluxo-item img {
        max-width: 100px;
        height: auto;
        margin-right: 15px;
    }
}


/*----------ABOUT-DLL------------*/
.about-dll {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #80FF80;
    height: auto;
}

.about-dll .text1 h3,
.about-dll .text2 h3,
.about-dll .text3 h3 {
    text-align: left;
}

.about-dll p {
    margin: 4px 0; 
    line-height: 1.2;
}

.about-dll .text1 ,
.about-dll .text2 ,
.about-dll .text3  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.about-dll h3 {
    margin: 5px 0;
    font-size: 1.5rem;
}

.about-dll span {
    color: white;
    font-weight: bold;
}

.about-dll h3 span {
    font-weight: bold;
}