@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;300;400;600;700&display=swap');

body {
    font-family: 'Sora', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f8f9fa;
}

.navbar {
    background-color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar a.active {
    color: #66cc66;
}

.navbar a:hover {
    color: #66cc66;
}

.dropdown {
    position: relative; /* Para que o dropdown-menu seja posicionado relativo a este item */
}


.dropdown-menu {
    position: absolute;
    background-color: #000000;
    border: 1px solid #444;
    list-style: none;
    padding: 0;
    margin: 0;
    display: none;
    left: 0; /* Alinha o menu à esquerda do pai */
    top: 100%;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 0.3s ease;
}

.dropdown-menu.show {
    display: block;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.dropdown-menu li {
    border-bottom: 1px solid #555;
}

.dropdown-menu li:last-child {
    border-bottom: none;
}

.dropdown-menu li a {
    color: white;
    text-decoration: none;
    display: block;
    padding: 10px;
}

.dropdown-menu li a:hover,
.dropdown-menu li a:focus,
.dropdown-menu li a:active {
    color: #66cc66;
    background-color: #444;
}


.home, .about, .community, .tef {
    text-align: center;
}

.makeEmbed {
    background-color: #F0F0F0;
    padding: 50px 0;
    text-align: center;
}


.makeEmbed h4 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: black;
}

.icon-image{
    width: 90px;
}



.makeEmbed button{
    background-color: #80FF80;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;

}

.makeEmbed button:hover {
    background-color: #57a457;
}

.options-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 50px;
    background-color: white;
}

.option-card {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto auto auto; 
    row-gap: 20px;
    column-gap: 5px;
    align-items: start;
    padding: 30px;
    max-width: 800px;
    text-align: start;
}

.option-card img {
    grid-column: 1;
    grid-row: span 3;
    width: 100px;
    align-self: start;
    justify-self: end;

}

.option-card .title {
    grid-column: 2;
    grid-row: 1;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

.option-card p:nth-of-type(2) {
    grid-column: 2;
    grid-row: 2;
    margin: 0;
}

.option-card button {
    grid-column: 2;
    grid-row: 3;
    justify-self: start;
    padding: 10px 20px;
    background-color: #80FF80;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.option-card button:hover {
    background-color: #57a457;
}

.contact {
    background-color: #000000;
    padding: 15px 40px;
    text-align: left;
}



.contact-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.contact-item {
    margin: 10px;
    min-width: 200px;
}

.contact-item img{
    width: 100%;
}

.contact-item h3 {
    color: #80FF80;
    font-size: 20px;
    margin-bottom: 10px;
}

.contact-item a {
    color: white;
    text-decoration: none;
}

.contact-item p {
    color: white;
    text-decoration: none;
    width: 300px;
}

.contact-item a:hover {
    text-decoration: underline;
}

.col1 img{
    width: 200px;
}


.icon98 {
    background-color: #80FF80;
    border-radius: 50%;
    padding: 5px;
}

.social-media ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 10px;
}

.social-media a {
    color: white;
    text-decoration: none;
}

.social-media a:hover {
    text-decoration: underline;
}

.copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #333;
    color: white;
    height: 25px;
}

.footer {
    background-color: #333;
    color: white;
    text-align: center;
}
@media(max-width:768px){
    .navbar ul{
        display: flex;
        justify-content: center;
    }
    .options-section {
        display: flex;
        flex-direction: column;
        padding: 25px;
    }
    .contact-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .makeEmbed h4{
        font-size: 1.4rem;
    }
}

html, body {
    height: 100%;
    width: 100%;
    margin: 0;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    width: 100%;
}

.App {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 1;
}

footer.footer {
    background-color: #f8f9fa;
    text-align: center;
    padding: 10px 0;
}